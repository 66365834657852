import React, { Component } from 'react';
import Formulario from './InformesForm';

class Informes extends Component{
    componentDidMount = () => {
        const { getModulo, listarPaises, listarDepartamentos } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4])
        listarPaises();
        listarDepartamentos();
    }
    render(){
        const { loader, modulo, reporteListadoColaboradores, reporteBirthdays,
            listaP, excelReporteEmpleador, reporteRecibosPeriodo, listarColaborador,
            listarPlanillasRecibos, reportePromedioIngresos, listaPeriodo,
            reporteListadoFamilia, excelListadoFamilia, listarColaboradorTodos,
            reporteListadoPermisos, reporteSuspendidosIGSS, reportePrestamos,
            excelListadoColaboradores, deptos, reporteLiquidacionISR, excelLiquidacionISR } = this.props;
        return(
            <div>
                <Formulario
                    loader = {loader}
                    modulo = {modulo}
                    reporteListadoColaboradores = {reporteListadoColaboradores}
                    reporteBirthdays = {reporteBirthdays}
                    obtenerPaises = {listaP}
                    excelReporteEmpleador = {excelReporteEmpleador}
                    listarColaborador = {listarColaborador}
                    reporteRecibosPeriodo = {reporteRecibosPeriodo}
                    listarPlanillasRecibos = {listarPlanillasRecibos}
                    reportePromedioIngresos = {reportePromedioIngresos}
                    listaPeriodo = {listaPeriodo}
                    reporteListadoFamilia = {reporteListadoFamilia}
                    excelListadoFamilia = {excelListadoFamilia}
                    listarColaboradorTodos = {listarColaboradorTodos}
                    reporteListadoPermisos = {reporteListadoPermisos}
                    reporteSuspendidosIGSS = {reporteSuspendidosIGSS}
                    reportePrestamos = {reportePrestamos}
                    excelListadoColaboradores = {excelListadoColaboradores}
                    deptos = {deptos}
                    reporteLiquidacionISR = {reporteLiquidacionISR}
                    excelLiquidacionISR = {excelLiquidacionISR}
                />
            </div>
        );
    }
}

export default Informes;
